import {React, useState} from 'react';
import './arrowUp.css';

const ArrowUp = () => {
  const [clicked, setClicked] = useState(false);

  function topScreen() {
    var target = document.getElementById('top');
    target.scrollIntoView({ behavior: 'smooth' });
    setClicked(true);
    setTimeout(() => {setClicked(false)}, 300)
  };

  return (
    <div onClick={topScreen} id='arrow-up' className={clicked ? 'arrowClicked' : ''}><ion-icon name="chevron-up-outline"></ion-icon></div>
  )
}

export default ArrowUp;
