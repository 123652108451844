import React from 'react';
import './buttonWp.css';

const ButtonWp = ({btnHref, btnText}) => {
  return (
    <div className='buttonWp text-dark'>
      <a href={btnHref} target='_blank' rel='noreferrer noopener'>{btnText} <ion-icon name="logo-whatsapp"></ion-icon></a>
      
    </div>
  )
}

export default ButtonWp;
