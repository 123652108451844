import React from 'react';
import './hero.css';
import { Cta } from '../../components';
import {simbol, logo} from '../../assets';

const Hero = () => {
  return (
    <section id='pagina-inicial' className='hero flex section-padding text-light'>
      <div className="hero__content">
        <div className="bar"></div>
        <Cta title={<h1>Aumente o faturamento do seu negócio <span>através dos anúncios online.</span></h1>} text='Somos uma agência especializada
em estratégias de tráfego pago
para empresas que querem aumentar
o seu faturamento.' btnHref='https://api.whatsapp.com/send/?phone=5535992429142&text=Ol%C3%A1%21+Vim+atrav%C3%A9s+do+site+e+gostaria+de+agendar+minha+consultoria+gratuita.&type=phone_number&app_absent=0' btnText='Agende sua Consultoria Gratuita!'/>
      </div>
      <div className="hero__image flex">
        <picture>
          <source media="(min-width: 900px)" srcSet={simbol}/>
          <img src={logo} alt="IfItDoesntMatchAnyMedia"/>
         </picture>
      </div>
    </section>
  )
}

export default Hero;
