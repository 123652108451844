import React from 'react';
import './contactButton.css';

const ContactButton = ({btnHref, btnText}) => {
  return (
    <div className='contactButton text-light'>
      <a href={btnHref} target='_blank' rel='noreferrer noopener'>{btnText} <ion-icon name="arrow-up-outline"></ion-icon></a>
      
    </div>
  )
}

export default ContactButton;
