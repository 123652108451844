import './App.css';
import { ArrowUp } from './components';
import {Header, Hero, Services, FrequencyQuestions, Footer} from './containers'

function App() {
  return (
    <div className="App content-grid">
      <Header/>
      <main className='full-width content-grid'>
        <Hero/>
        <Services/>
        <FrequencyQuestions/>
      </main>
      <ArrowUp />
      <Footer/>
    </div>
  );
}

export default App;
