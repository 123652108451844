import React from 'react';
import './frequencyQuestions.css';
import { Accordion } from '../../components';

const FrequencyQuestions = () => {
  return (
    <section id='perguntas-frequentes' className='frequencyQuestions text-light section-padding'>
      <h2>Perguntas Frequentes</h2>
      <div className="frequencyQuestions-content">
        <Accordion title='Qual o valor mínimo para investimento?' text='Não há um limite estabelecido, mas recomendamos um investimento mínimo de R$600,00.'/>
        <Accordion title='Como funciona a consultoria gratuita?' text='A consultoria gratuita é feita através de uma reunião online, de até 30 minutos, na qual iremos elaborar, com base no seu negócio, um plano de ação para melhorar os seus resultados através dos anúncios online.'/>
        <Accordion title='Quanto tempo leva para ter resultados?' text='Tudo vai depender do nível de maturidade do seu negócio, experiência da equipe comercial e do tipo de produto ou serviço que você vende. No entanto, em média, começaremos a ter resultado logo no primeiro mês, os quais se tornam mais significativos a partir do 3º mês, desde que todas as recomendações sugeridas sejam seguidas.'/>
        <Accordion title='Será que funciona para o meu negócio?' text='Todos os dias existem milhares de pessoas buscando pelos seus serviços ou produtos, se você não aparecer para elas, o seu concorrente vai. Então, se quiser sair na frente, o tráfego pago é para você!'/>
        <Accordion title='Para anunciar, eu preciso de um site?' text='Possuir um site não é obrigatório para começar a anunciar, é possível direcionar o seu público-alvo diretamente para o seu WhatsApp. Mas, caso seja do interesse, podemos desenvolver um site juntos.'/>
      </div>
    </section>
  )
}

export default FrequencyQuestions;
