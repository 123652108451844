import { React, useState } from 'react';
import './accordion.css';

const Accordion = ({ title, text }) => {
  const [isActive, setIsActive] = useState(false);

  const toggleAccordion = () => {
    setIsActive(!isActive);
  };

  return (
    <div className='accordion-container'>
      <button className={`accordion flex ${isActive ? 'open-accordion' : ''}`} onClick={toggleAccordion}>
        {title}
        <div className="toggle-accordion flex">
          <ion-icon name="add-outline"></ion-icon>
        </div>
      </button>
      <div className={`accordion-content ${isActive ? 'accordion-content-active' : ''}`}>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Accordion;
