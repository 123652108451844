import React from 'react';
import './footer.css';

const Footer = () => {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <footer className='text-light'>
      <p>Golden Seren Midia &copy;{year}. Todos os direitos reservados. CNPJ: 48.228.523/0001-16</p>
    </footer>
  )
}

export default Footer;
