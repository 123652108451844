import React from 'react';
import './cta.css';
import ButtonWp from '../buttonWp/ButtonWp';

const Cta = ({title, text, btnHref, btnText}) => {
  return (
    <div className='cta'>
      <>{title}</>
      <p>{text}</p>
      <ButtonWp btnHref={btnHref} btnText={btnText}/>
    </div>
  )
}

export default Cta;
