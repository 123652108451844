import React from 'react';
import './services.css';

const services = () => {
  return (
    <section id='servicos' className='full-width services bg-light text-light section-padding flex'>
      <h2 className='text-dark'>Serviços</h2>
    <div className="swiper-button-prev-custom"><ion-icon name="chevron-back-outline"></ion-icon></div>
    <div className="swiper-button-next-custom"><ion-icon name="chevron-forward-outline"></ion-icon></div>
      <swiper-container
        navigation-next-el=".swiper-button-next-custom"
        navigation-prev-el=".swiper-button-prev-custom"
    

      pagination-clickable="true" keyboard="true" navigation="true" loop="true" slides-per-view="auto" space-between="30">
        <swiper-slide>
          <div className="services-icons flex">
          <i className='bx bxl-google'></i>
          <i className='bx bxl-facebook-circle' ></i>
          <i className='bx bxl-instagram' ></i>
          </div>
          <h3>Tráfego em todas as plataformas</h3>
          <div className="bar"></div>
          <p>Os nossos serviços de gestão não se limitam a uma única plataforma, iremos elaborar uma estratégia para que você esteja onde o seu público está buscando pelo seu serviço ou produto.</p>
        </swiper-slide>
        <swiper-slide>
          <div className="services-icons flex">
          <i className='bx bx-trending-up'></i>
          </div>
          <h3>Mapeamento e referência de anúncios</h3>
          <div className="bar"></div>
          <p>Ao contratar os nossos serviços, faremos um mapeamento completo dos anúncios que melhor estão performando dentro do seu nicho de atuação, buscando referências que estão sendo utilizadas no mercado. Com base nisso, iremos fazer recomendações de criativos a serem produzidos para aplicarmos as melhores práticas e aumentar o seu resultado de forma rápida e assertiva.</p>
        </swiper-slide>
        <swiper-slide>
          <div className="services-icons flex">
          <ion-icon name="newspaper-outline"></ion-icon>
          </div>
          <h3>Relatórios</h3>
          <div className="bar"></div>
          <p>Você receberá relatórios quinzenais para prestação de contas e resultados, assim será possível acompanharmos a evolução do projeto de forma transparente e objetiva.</p>
        </swiper-slide>
        <swiper-slide>
          <div className="services-icons flex">
          <i className='bx bxs-vector' ></i>
          </div>
          <h3>Setup e Veiculação de Campanhas</h3>
          <div className="bar"></div>
          <p>Realizaremos a criação e configuração de todas as contas necessárias para anunciar. Além disso, criaremos, de forma padronizada, todos os recursos necessários para iniciar os anúncios online. Instalação de tags no site. Tudo para que os anúncios atinjam a máxima performance.</p>
        </swiper-slide>
        <swiper-slide>
          <div className="services-icons flex">
          <i className='bx bxs-edit' ></i>
          </div>
          <h3>Otimização de Campanhas</h3>
          <div className="bar"></div>
          <p>A partir da seleção precisa dos objetivos do projeto, otimizaremos diariamente suas campanhas para maximizar os resultados, o retorno sobre o investimento realizado, bem como a organização financeira.</p>
        </swiper-slide>
        <swiper-slide>
          <div className="services-icons flex">
          <i className='bx bx-headphone' ></i>
          </div>
          <h3>Reuniões Periódicas</h3>
          <div className="bar"></div>
          <p>Periodicamente, realizaremos reuniões com o foco de discutir os resultados e alinhar as expectativas e objetivos do projeto. Esse encontro é essencial para que possamos debater sobre e compreender de forma clara o andamento de nossa estratégia. Estaremos sempre juntos no processo.</p>
        </swiper-slide>
        <swiper-slide>
          <div className="services-icons flex">
          <i className='bx bx-desktop' ></i>
          </div>
          <h3>Dashboard Personalizada</h3>
          <div className="bar"></div>
          <p>Dashboard Personalizada Você receberá acesso a uma dashboard personalizada para o seu negócio, dessa forma, poderá acompanhar de forma descomplicada os resultados e outras métricas importantes.</p>
        </swiper-slide>
      </swiper-container>
    </section>
  )
}

export default services;
