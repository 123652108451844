import {React, useState} from 'react';
import './header.css';
import {logo} from '../../assets';
import ContactButton from '../../components/contactButton/ContactButton';

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);

  window.addEventListener('resize', () => {
    document.querySelector('nav').classList.add('no-transition');
    setTimeout(() => { document.querySelector('nav').classList.remove('no-transition');}, 500);
  })

  function toggleMenu() {
    setOpenMenu(!openMenu);
  }

  return (
    <header id='top' className='bg-dark flex text-light'>
      <div className="header__logo">
        <img src={logo} alt="logo" />
      </div>
      <nav className={openMenu ? 'nav-active' : ''}>
        <ul className='flex' onClick={toggleMenu}>
          <li><a href="#pagina-inicial" rel=''>Página Inicial</a></li>
          <li><a href="#servicos" rel=''>Serviços</a></li>
          <li><a href="#perguntas-frequentes" rel=''>Perguntas Frequentes</a></li>
        </ul>
      </nav>
      <div className="header__toggle-button flex">
        <span onClick={toggleMenu}></span>
      </div>
      <div className="header__contactButton">
        <ContactButton btnHref='https://api.whatsapp.com/send/?phone=5535992429142&text=Ol%C3%A1%21+Vim+atrav%C3%A9s+do+site+e+gostaria+de+agendar+minha+consultoria+gratuita.&type=phone_number&app_absent=0' btnText='Fale Conosco'/>
      </div>
    </header>
  )
}

export default Header;
